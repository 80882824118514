import { QBox, QFormControl, QHeading, useCurrentUser } from '@qualio/ui-components';
import React from 'react';
import { TemplateDTO } from '../../../types';
import { SectionedContentEditor } from '../../SectionedContentEditor/SectionedContentEditor';
import { PageOrientation, QualioContentType, QualioSectionedContent } from '../../shared/CKEditor/models';
import { PresentationUtils } from '../../shared/CKEditor/util/PresentationUtils';
import { CKEditorInstance } from '../../shared/CKEditor/types/CKEditorInstance';
import { sortSections } from '../../../utils/sectionUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type DefaultContentProps = {
  setTemplateDto: (event: TemplateDTO) => void;
  templateDto: TemplateDTO;
};

const DefaultContent = ({ setTemplateDto, templateDto }: DefaultContentProps) => {
  const { companyId } = useCurrentUser();
  const { pasteFromOfficeEnhanced } = useFlags();
  const defaultContentSections = templateDto.sections.map((field) => {
    return {
      content: field.content,
      position: field.position,
      title: field.title,
      type: field.type,
      attachments: null,
      id: field.id,
    };
  });

  sortSections(defaultContentSections);

  const defaultContent: QualioSectionedContent = {
    sections: defaultContentSections,
    content_type: QualioContentType.Template,
    orientation: PageOrientation.Portrait,
    id: null,
  };

  const handleCKEditorChange = (editor: CKEditorInstance) => {
    const editorSections = PresentationUtils.splitEditorTextIntoSections(editor.getData(), defaultContent);
    editorSections.reverse();
    defaultContent.sections.forEach((section) => {
      const editorSection = editorSections.pop();
      section.content = editorSection!.sectionText;
    });
    setTemplateDto({ ...templateDto, sections: defaultContent.sections });
    return Promise.resolve();
  };

  return (
    <QBox className="stepper-content" pl="2" pr="2">
      <QBox pb={5}>
        <QHeading size="md">Default content</QHeading>
      </QBox>
      <QFormControl>
        <SectionedContentEditor
          data={defaultContent}
          companyId={companyId}
          handleChange={handleCKEditorChange}
          pasteFromOfficeEnhanced={pasteFromOfficeEnhanced}
        />
      </QFormControl>
    </QBox>
  );
};

export default DefaultContent;
