import { convertToNullableNumber } from '../utils/textUtil';
import { SectionDTO, TemplateDTO, SectionResolutionDTO } from './templateDTO';
import { MedtechUserGroup } from './templateDetail';

export class TemplateRequestBody {
  name: string;
  type: string;
  prefix: string;
  company_id: number;
  document_effective_on_approval: boolean;
  document_review_period: number | null;
  document_review_due_in_days: number | null;
  document_approval_due_in_days: number | null;
  document_text_sections_visible: boolean;
  training_available: boolean;
  training_required: boolean;
  sections: SectionDTO[];
  resolutions: SectionResolutionDTO[];
  file_download_user_groups: MedtechUserGroup[];

  constructor(templateDto: TemplateDTO, companyId: number, resolutions: SectionResolutionDTO[]) {
    this.name = templateDto.title;
    this.type = templateDto.type;
    this.prefix = templateDto.id;
    this.company_id = companyId;
    this.sections = templateDto.sections;
    this.document_effective_on_approval = templateDto.effectiveOnApproval === 'yes';
    if (templateDto.periodicReviewPeriod === '0') {
      // this should be no periodic review at all
      this.document_review_period = null;
    } else {
      this.document_review_period = convertToNullableNumber(templateDto.periodicReviewPeriod);
    }
    this.document_review_due_in_days = convertToNullableNumber(templateDto.reviewLimit);
    this.document_approval_due_in_days = convertToNullableNumber(templateDto.approvalLimit);
    this.document_text_sections_visible = templateDto.showSectionTitles === 'yes';
    this.training_available = templateDto.eligibleForTraining === 'yes';
    this.training_required = templateDto.requireRetraining === 'yes';
    this.resolutions = resolutions;
    this.file_download_user_groups = [...templateDto.fileDownloadUserGroups];
  }
}
