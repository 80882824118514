import {
  QBox,
  QHeading,
  QInput,
  QRadio,
  QRadioGroup,
  QSelect,
  QSelectPlaceholder,
  QStack,
  QText,
} from '@qualio/ui-components';
import React, { useState } from 'react';
import { MedtechUserGroup, TemplateDTO } from '../../../types';
import { CreateEditTemplatePage } from '../../../views/CreateEditTemplateView/CreateEditTemplatePage';
import { labels } from '../../../lang/labels';

const reviewOptions = [
  { value: '0', label: 'None' }, // we conform this to null in the payload, Qselect does not support null values
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '24', label: '24' },
  { value: '30', label: '30' },
  { value: '36', label: '36' },
  { value: '42', label: '42' },
  { value: '48', label: '48' },
  { value: '54', label: '54' },
  { value: '60', label: '60' },
  { value: '66', label: '66' },
  { value: '72', label: '72' },
  { value: '78', label: '78' },
  { value: '84', label: '84' },
];

export type PropertiesProps = {
  setTemplateDto: (event: TemplateDTO) => void;
  templateDto: TemplateDTO;
  setPageError: (page: CreateEditTemplatePage, value: boolean) => void;
};

const REVIEW_APPROVAL_UPPER_LIMIT = 2147483647;
const NO_ERROR = '';
const FORMAT_ERROR = 'format_error';
const LIMIT_ERROR = 'limit_error';

const Properties = (props: PropertiesProps) => {
  const [reviewLimitError, setReviewLimitError] = useState<string>(NO_ERROR);
  const [approvalLimitError, setApprovalLimitError] = useState<string>(NO_ERROR);

  const setPropertyPageError = (value: boolean) => {
    props.setPageError(CreateEditTemplatePage.PropertiesNewEditor, value);
  };

  return (
    <QBox className="stepper-content" px="2">
      <QBox mb={5}>
        <QHeading size="md">Properties</QHeading>
      </QBox>
      <QBox mt={6}>
        <QStack direction={['column']}>
          <QText fontSize="sm">Eligible for training</QText>
          <QRadioGroup
            value={props.templateDto.eligibleForTraining}
            onChange={(val) => props.setTemplateDto({ ...props.templateDto, eligibleForTraining: val })}
          >
            <QStack direction={['column']} spacing="0">
              <QRadio value={'yes'}>Yes</QRadio>
              <QRadio value={'no'}>No</QRadio>
            </QStack>
          </QRadioGroup>
        </QStack>
      </QBox>
      <QBox mt={6}>
        <QStack direction={['column']}>
          <QText fontSize="sm">Require Training on each version</QText>
          <QRadioGroup
            value={props.templateDto.requireRetraining}
            onChange={(val) => props.setTemplateDto({ ...props.templateDto, requireRetraining: val })}
          >
            <QStack direction={['column']} spacing="0">
              <QRadio value={'yes'}>Yes</QRadio>
              <QRadio value={'no'}>No</QRadio>
            </QStack>
          </QRadioGroup>
        </QStack>
      </QBox>
      <QBox mt={6}>
        <QStack direction={['column']}>
          <QText fontSize="sm">Periodic review period (months)</QText>
          <QSelect
            options={reviewOptions}
            value={props.templateDto.periodicReviewPeriod}
            onChange={(val) => {
              if (val) {
                props.setTemplateDto({ ...props.templateDto, periodicReviewPeriod: val.value });
              }
            }}
            isLoading={false}
            isSearchable={false}
          >
            <QSelectPlaceholder>
              <QText>None</QText>
            </QSelectPlaceholder>
          </QSelect>
        </QStack>
      </QBox>
      <QBox mt={6}>
        <QStack direction={['column']}>
          <QText fontSize="sm">Review limit (days)</QText>
          <QInput
            placeholder="None"
            value={props.templateDto.reviewLimit}
            onChange={(e) => {
              setReviewLimitError(NO_ERROR);
              if (approvalLimitError === NO_ERROR) {
                setPropertyPageError(false);
              }
              props.setTemplateDto({ ...props.templateDto, reviewLimit: e.target.value.trim() });
            }}
            onBlur={(e) => {
              const value = e.target.value.trim();
              const regex = new RegExp(/^\d+( )*$/);
              if (value !== '' && !regex.test(value)) {
                setReviewLimitError(FORMAT_ERROR);
                setPropertyPageError(true);
              } else if (Number(value) > REVIEW_APPROVAL_UPPER_LIMIT) {
                setReviewLimitError(LIMIT_ERROR);
                setPropertyPageError(true);
              }
            }}
            isInvalid={reviewLimitError !== NO_ERROR}
          />
          {reviewLimitError === FORMAT_ERROR && (
            <QText fontSize="sm" color={'red'}>
              Value has to be 0 or a positive number
            </QText>
          )}
          {reviewLimitError === LIMIT_ERROR && <QText color={'red'}>Value is too large</QText>}
        </QStack>
      </QBox>
      <QBox mt={6}>
        <QStack direction={['column']}>
          <QText fontSize="sm">Approval limit (days)</QText>
          <QInput
            placeholder="None"
            value={props.templateDto.approvalLimit}
            onChange={(e) => {
              setApprovalLimitError(NO_ERROR);
              if (reviewLimitError === NO_ERROR) {
                setPropertyPageError(false);
              }
              props.setTemplateDto({ ...props.templateDto, approvalLimit: e.target.value });
            }}
            onBlur={(e) => {
              const value = e.target.value.trim();
              const regex = new RegExp(/^\d+( )*$/);
              if (value !== '' && !regex.test(value)) {
                setApprovalLimitError(FORMAT_ERROR);
                setPropertyPageError(true);
              } else if (Number(value) > REVIEW_APPROVAL_UPPER_LIMIT) {
                setApprovalLimitError(LIMIT_ERROR);
                setPropertyPageError(true);
              }
            }}
            isInvalid={approvalLimitError !== NO_ERROR}
          />
          {approvalLimitError === FORMAT_ERROR && (
            <QText fontSize="sm" color={'red'}>
              Value has to be 0 or a positive number
            </QText>
          )}
          {approvalLimitError === LIMIT_ERROR && (
            <QText fontSize="sm" color={'red'}>
              Value is too large
            </QText>
          )}
        </QStack>
      </QBox>
      <QBox mt={6}>
        <QText fontSize="sm">{labels.lblOptUsersDownloadOriginalFile}</QText>
        <QStack direction={['column']} mt="2">
          <FileDownloadPermissionsSelect templateDto={props.templateDto} setTemplateDto={props.setTemplateDto} />
        </QStack>
      </QBox>
      <QBox mt={6}>
        <QStack direction={['column']}>
          <QText fontSize="sm">Effective on approval</QText>
          <QRadioGroup
            value={props.templateDto.effectiveOnApproval}
            onChange={(val) => props.setTemplateDto({ ...props.templateDto, effectiveOnApproval: val })}
          >
            <QStack direction={['column']} spacing="0">
              <QRadio value={'yes'}>Yes</QRadio>
              <QRadio value={'no'}>No</QRadio>
            </QStack>
          </QRadioGroup>
        </QStack>
      </QBox>

      <QBox mt={6}>
        <QStack direction={['column']}>
          <QText fontSize="sm">Show section titles</QText>
          <QRadioGroup
            value={props.templateDto.showSectionTitles}
            onChange={(val) => props.setTemplateDto({ ...props.templateDto, showSectionTitles: val })}
          >
            <QStack direction={['column']} spacing="0">
              <QRadio value={'yes'}>Yes</QRadio>
              <QRadio value={'no'}>No</QRadio>
            </QStack>
          </QRadioGroup>
        </QStack>
      </QBox>
    </QBox>
  );
};

const FileDownloadPermissionsSelect: React.FC<{
  templateDto: TemplateDTO;
  setTemplateDto: (dto: TemplateDTO) => void;
}> = ({ templateDto, setTemplateDto }) => {
  const determineFileDownloadPermissionsSelectValue = (
    fileDownloadUserGroups: MedtechUserGroup[],
  ): MedtechUserGroup | 'owner' => {
    if (fileDownloadUserGroups.includes('read_only')) {
      return 'read_only';
    }
    if (fileDownloadUserGroups.includes('normal')) {
      return 'normal';
    }
    if (fileDownloadUserGroups.includes('quality')) {
      return 'quality';
    }
    return 'owner';
  };

  return (
    <QSelect<string, MedtechUserGroup | 'owner'>
      options={[
        { value: 'owner', label: labels.lblOptUsersDownloadOriginalFileValues.owner },
        { value: 'quality', label: labels.lblOptUsersDownloadOriginalFileValues.quality },
        { value: 'normal', label: labels.lblOptUsersDownloadOriginalFileValues.normal },
        {
          value: 'read_only',
          label: labels.lblOptUsersDownloadOriginalFileValues.read_only,
        },
      ]}
      isSearchable
      onChange={(selected) => {
        if (!selected) {
          return;
        }
        let fileDownloadUserGroups: MedtechUserGroup[] = [];
        switch (selected.value) {
          case 'owner':
            break;
          case 'quality':
            fileDownloadUserGroups = ['quality'];
            break;
          case 'normal':
            fileDownloadUserGroups = ['quality', 'normal'];
            break;
          case 'read_only':
            fileDownloadUserGroups = ['quality', 'normal', 'read_only'];
            break;
        }
        setTemplateDto({
          ...templateDto,
          fileDownloadUserGroups,
        });
      }}
      value={determineFileDownloadPermissionsSelectValue(templateDto.fileDownloadUserGroups)}
    ></QSelect>
  );
};

export default Properties;
