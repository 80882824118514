import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
import { Autosave } from '@ckeditor/ckeditor5-autosave';
import {
  Bold,
  Italic,
  Strikethrough,
  Subscript,
  Superscript,
  Underline,
} from '@ckeditor/ckeditor5-basic-styles';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { CodeBlock } from '@ckeditor/ckeditor5-code-block';
import { Plugin } from '@ckeditor/ckeditor5-core';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import {
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
} from '@ckeditor/ckeditor5-font';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line';
import {
  AutoImage,
  Image,
  ImageCaption,
  ImageInsert,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
} from '@ckeditor/ckeditor5-image';
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
import { AutoLink, Link, LinkImage } from '@ckeditor/ckeditor5-link';
import { List, ListProperties } from '@ckeditor/ckeditor5-list';
import { MultiLevelList } from '@ckeditor/ckeditor5-list-multi-level';
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office';
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format';
import {
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
} from '@ckeditor/ckeditor5-special-characters';
import {
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
} from '@ckeditor/ckeditor5-table';
import { TextTransformation } from '@ckeditor/ckeditor5-typing';
import { FileRepository } from '@ckeditor/ckeditor5-upload';

import { environment } from '../../../../environments/environment';
import { QualioDisableSectionHeadingPlugin } from '../plugins/LockSectionHeaders/QualioDisableSectionHeadingPlugin';
import { CKEditorInstance } from '../types/CKEditorInstance';
import { CKEditorConfig } from './CKEditorConfig';
import { Mention } from '@ckeditor/ckeditor5-mention';
import { PageBreak } from '@ckeditor/ckeditor5-page-break';
import { QualioSectionHeadingPlugin } from '../plugins/SectionHeaders/QualioSectionHeadingPlugin';

declare global {
  const CKEditor5: any;
}

export class EditorConfig {
  public static readonly CONTENT_EDITOR_PLUGINS: typeof Plugin[] = [
    Alignment,
    Autoformat,
    AutoImage,
    AutoLink,
    Autosave,
    CKEditor5.fileUpload.QualioFileUpload,
    BlockQuote,
    HorizontalLine,
    Essentials,
    Paragraph,
    Bold,
    FontColor,
    FontSize,
    FontFamily,
    FontBackgroundColor,
    Image,
    ImageInsert,
    ImageUpload,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    PageBreak,
    ImageToolbar,
    LinkImage,
    MediaEmbed,
    Indent,
    IndentBlock,
    RemoveFormat,
    Italic,
    Link,
    List,
    ListProperties,
    CKEditor5.sectionList.QualioSectionList,
    QualioSectionHeadingPlugin,
    MultiLevelList,
    PasteFromOffice,
    Mention,
    QualioDisableSectionHeadingPlugin,
    CKEditor5.checkbox.QualioCheckbox,
    CKEditor5.smartlink.QualioSmartlink,
    FileRepository,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    SpecialCharactersEssentials,
    Strikethrough,
    Superscript,
    Subscript,
    Heading,
    CodeBlock,
    Underline,
    Table,
    TableColumnResize,
    TableToolbar,
    TableProperties,
    TableCellProperties,
    TableCaption,
    TextTransformation,
  ];

  public static readonly CONTENT_EDITOR_TOOLBAR_ITEMS: string[] = [
    'undo',
    'redo',
    '|',
    'heading',
    '|',
    'bold',
    'italic',
    'underline',
    'fontSize',
    'fontColor',
    '|',
    'link',
    'qualioFileUploadButton',
    'uploadImage',
    '|',
    'alignment',
    '|',
    'bulletedList',
    'numberedList',
    'qualioSectionListButton',
    'multiLevelList',
    '|',
    'outdent',
    'indent',
    '|',
    'insertTable',
    'qualioCheckboxButton',
    'specialCharacters',
    'subscript',
    'superscript',
    '|',
    'codeBlock',
    'blockQuote',
    '|',
    'fontBackgroundColor',
    'strikethrough',
    '|',
    'removeFormat',
    'pageBreak',
  ];

  public static readonly TABLE_TOOLBAR_ITEMS: string[] = [
    'tableColumn',
    'tableRow',
    'mergeTableCells',
    'toggleTableCaption',
    'tableProperties',
    'tableCellProperties',
  ];

  public static readonly IMAGE_TOOLBAR_ITEMS: string[] = [
    'toggleImageCaption',
    'imageTextAlternative',
    '|',
    'imageStyle:alignLeft',
    'imageStyle:alignRight',
    'imageStyle:alignBlockLeft',
    'imageStyle:alignBlockRight',
    'imageStyle:alignCenter',
  ];

  public static readonly DocumentEditorSmartlinkSuggestTimeout = 100;
}

export const getCKEditorConfig = (
  companyId: number,
  autosaveHandler: (editor: CKEditorInstance) => Promise<void>,
): CKEditorConfig => {
  const contentEditorPlugins = [...EditorConfig.CONTENT_EDITOR_PLUGINS];
  return {
    plugins: contentEditorPlugins,
    toolbar: {
      items: EditorConfig.CONTENT_EDITOR_TOOLBAR_ITEMS,
      shouldNotGroupWhenFull: false,
    },
    licenseKey: process.env.REACT_APP_CKE_PREMIUM_FEATURES_LICENSE_KEY,
    indentBlock: {
      offset: 2,
      unit: 'em',
    },
    table: {
      contentToolbar: EditorConfig.TABLE_TOOLBAR_ITEMS,
    },
    image: {
      toolbar: EditorConfig.IMAGE_TOOLBAR_ITEMS,
    },
    heading: {
      options: [
        {
          model: 'paragraph',
          title: 'Paragraph',
          class: 'ck-heading_p',
        },
        {
          model: 'heading1',
          view: 'h1',
          title: 'Heading 1',
          class: 'ck-heading_h1',
        },
        {
          model: 'heading2',
          view: 'h2',
          title: 'Heading 2',
          class: 'ck-heading_h2',
        },
        {
          model: 'heading3',
          view: 'h3',
          title: 'Heading 3',
          class: 'ck-heading_h3',
        },
        {
          model: 'heading4',
          view: 'h4',
          title: 'Heading 4',
          class: 'ck-heading_h4',
        },
      ],
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
    },
    mention: CKEditor5.smartlink.QualioSmartlink.getMentionConfig(
      companyId,
      environment.GLOBAL_SEARCH_SERVICE_URL,
    ),
    fontFamily: {
      options: ['default'],
    },
    fontSize: {
      options: [
        'small',
        {
          title: 'Normal',
          model: '14px',
          upcastAlso: [],
          view: '',
        },
        {
          title: 'Large',
          model: '19.6px',
          upcastAlso: [],
          view: '',
        },
      ],
    },
    simpleFileUpload: {
      url: environment.MEDTECH_API + '/attachments',
      fileTypes: ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.jpg', '.png'],
    },
    simpleUpload: {
      uploadUrl: environment.MEDTECH_API + '/attachments',
      withCredentials: true,
    },
    autosave: {
      save: autosaveHandler,
      waitingTime: 3000,
    },
  };
};
