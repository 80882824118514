export enum PageOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

export enum QualioContentType {
  Template = 'template',
  Document = 'document',
}

export interface QualioSectionedContent {
  content_type: QualioContentType;
  id: number | null;
  orientation: PageOrientation;
  sections: QualioContentSection[];
}

export interface QualioContentSection {
  attachments: Attachment[] | null;
  content: string;
  id: number | null;
  position: number;
  title: string;
  type: string;
}

export type QualioDocumentSection = {
  attachments: Attachment[] | null;
  content: string;
  document_id: number | null;
  id: number | null;
  modified_at: number | null;
  modified_by_id: number | null;
  position: number;
  template_section_id: number | null;
  title: string;
  type: string;
};

export type Attachment = {
  date_added: number;
  filename: string;
  id: number;
  link: string;
  preview_state: string;
  preview_type: string;
  preview_url: string;
  url: string;
};

export interface SmartlinksSuggest {
  search: string;
}

export interface SmartlinksResolve {
  code: string;
}

export type UserInformation = {
  id: number;
  full_name: string;
  company_name: string;
  company_id: number;
};
// TO BE REMOVED FROM EDITOR DOMAIN START
export type QualioDocument = {
  approval_date: number | null;
  approvers_and_timestamps: string[][];
  audit_groups: {
    review_group: [{ user_id: number }];
    approver_group: [{ user_id: number }];
    training_group: [{ user_id: number }];
  };
  author: { full_name: string; id: number; email: string };
  code: string;
  company_id: number;
  company_logo: string;
  content_last_modified_at: number;
  effective_date: number | null;
  effective_on_approval: boolean;
  id: number;
  issue: { code: string };
  major_version: number;
  minor_version: number;
  orientation: PageOrientation;
  owner: { full_name: string; id: number; email: string };
  review_period: number;
  review_date: number | null;
  reviewers_and_timestamps: string[][];
  sections: QualioContentSection[];
  status_id: string;
  status: string;
  superseded_date?: string;
  tag_ids: [number];
  title: string;
  training_available: boolean;
  training_required: boolean;
  type: string;
  content_type: QualioContentType;
};

export type QualioDocumentPatch = {
  content_last_modified_at: number;
  id: number | null;
  orientation: PageOrientation;
  sections: QualioDocumentSectionPatch[];
  title: string;
};

export type QualioDocumentSectionPatch = {
  content: string;
  id: number | null;
  type: string;
};
