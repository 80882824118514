import React from 'react';
import { QBox, QText } from '@qualio/ui-components';

import { MedtechUserGroup, TemplateDetail } from '../../../../../types';
import { labels } from '../../../../../lang/labels';

type PropertiesTabProps = {
  templateDetail: TemplateDetail | undefined;
};

const PropertiesTab: React.FC<PropertiesTabProps> = ({ templateDetail }) => {
  if (!templateDetail) {
    return <></>;
  }

  return (
    <>
      <QBox mt="6">
        <QText weight="semibold">Eligible for training</QText>
        <QText color="gray.500">{templateDetail.training_available ? 'Yes' : 'No'}</QText>
      </QBox>
      <QBox mt="6">
        <QText weight="semibold">Require retraining on each version</QText>
        <QText color="gray.500">{templateDetail.training_required ? 'Yes' : 'No'}</QText>
      </QBox>
      <QBox mt="6">
        <QText weight="semibold">Periodic review period</QText>
        <QText color="gray.500">
          {templateDetail.document_review_period == null ? 'None' : templateDetail.document_review_period}
        </QText>
      </QBox>
      <QBox mt="6">
        <QText weight="semibold">Review limit</QText>
        <QText color="gray.500">
          {templateDetail.document_review_due_in_days == null ? 'None' : templateDetail.document_review_due_in_days}
        </QText>
      </QBox>
      <QBox mt="6">
        <QText weight="semibold">Approval limit</QText>
        <QText color="gray.500">
          {templateDetail.document_approval_due_in_days == null ? 'None' : templateDetail.document_approval_due_in_days}
        </QText>
      </QBox>
      <QBox mt="6">
        <QText weight="semibold">Effective on approval</QText>
        <QText color="gray.500">{templateDetail.document_effective_on_approval ? 'Yes' : 'No'}</QText>
      </QBox>
      {templateDetail.sectioned_content && (
        <QBox mt="6">
          <QText weight="semibold">Show section titles</QText>
          <QText color="gray.500">{templateDetail.document_text_sections_visible ? 'Yes' : 'No'}</QText>
        </QBox>
      )}
      <QBox mt="6">
        <QText weight="semibold">{labels.lblOptUsersDownloadOriginalFile}</QText>
        <QText color="gray.500">{getFileDownloadUserGroupsValue(templateDetail.file_download_user_groups ?? [])}</QText>
      </QBox>
    </>
  );
};

const getFileDownloadUserGroupsValue = (fileDownloadUserGroups: MedtechUserGroup[]) => {
  if (fileDownloadUserGroups.includes('read_only')) {
    return labels.lblOptUsersDownloadOriginalFileValues.read_only;
  } else if (fileDownloadUserGroups.includes('normal')) {
    return labels.lblOptUsersDownloadOriginalFileValues.normal;
  } else if (fileDownloadUserGroups.includes('quality')) {
    return labels.lblOptUsersDownloadOriginalFileValues.quality;
  }

  return labels.lblOptUsersDownloadOriginalFileValues.owner;
};

export default PropertiesTab;
