import { templateApiClient } from './axios';
import { Template, TemplateDetail, TemplateRequestBody } from '../types';
import { convertStringToDate } from '../utils/dateUtils';
import { sortSections } from '../utils/sectionUtils';

function conformDates(rawTemplate: any): any {
  rawTemplate['created_time'] = convertStringToDate(rawTemplate['created_time']);
  rawTemplate['modified_time'] = convertStringToDate(rawTemplate['modified_time']);
  return rawTemplate;
}

const templateApi = {
  async getTemplatesByCompanyIdAndStatus(companyId: number, archived: boolean): Promise<Template[] | undefined> {
    const res = await templateApiClient.get(`/companies/${companyId}/templates?archived=${archived}`);
    const rawTemplates = res.data.data;
    for (const template of rawTemplates) {
      conformDates(template);
    }
    return rawTemplates as Template[];
  },

  async getTemplateByCompanyIdAndId(companyId: number, templateId: string | undefined): Promise<TemplateDetail> {
    const res = await templateApiClient.get(`/companies/${companyId}/templates/${templateId}`);
    const rawTemplate = res.data.data;
    const template = conformDates(rawTemplate) as TemplateDetail;
    sortSections(template.sections);
    return template;
  },

  async getTemplateVersionsByUuid(companyId: number, templateUuid: string): Promise<Template[]> {
    const res = await templateApiClient.get(`/companies/${companyId}/templates/${templateUuid}/versions`);
    const rawTemplates = res.data.data;
    for (const template of rawTemplates) {
      conformDates(template);
    }
    return rawTemplates as Template[];
  },

  async createTemplate(companyId: number, newTemplate: TemplateRequestBody): Promise<TemplateDetail> {
    const resOrError = await templateApiClient.post(`/companies/${companyId}/templates`, newTemplate);
    if (resOrError.data) {
      const rawTemplate = resOrError.data.data;
      const template = conformDates(rawTemplate) as TemplateDetail;
      sortSections(template.sections);
      return template;
    }

    throw resOrError;
  },

  async updateTemplate(companyId: number, templateId: string, template: TemplateRequestBody): Promise<TemplateDetail> {
    const resOrError = await templateApiClient.put(`/companies/${companyId}/templates/${templateId}`, template);
    if (resOrError.data) {
      const rawTemplate = resOrError.data.data;
      const createdTemplate = conformDates(rawTemplate) as TemplateDetail;
      sortSections(createdTemplate.sections);
      return createdTemplate;
    }

    throw resOrError;
  },

  archiveRestoreTemplate(companyId: number, templateId: string, archived: boolean) {
    const requestBody = { archived: archived };
    return templateApiClient.patch(`/companies/${companyId}/templates/${templateId}`, requestBody);
  },
};

export default templateApi;
